/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var scrollToggle = function() {

          var $header = $('#js-header');
          var $menu = $('#js-cat-menu');
          var $marker = $('#js-cat-header');

          var headerClass = function() {
            var scrollTop = $(window).scrollTop();
            if(scrollTop > 100) {
              $('body').addClass('st-scrolled');
            } else {
              $('body').removeClass('st-scrolled');
            }
          };

          headerClass();

          var stickyMenu = function() {
            if($('#js-cat-menu').length) {
              var scrollTop = $(window).scrollTop();
              var headerHeight = $header.outerHeight();
              var menuDistance = $marker.offset().top + $marker.outerHeight() - headerHeight;
              if(scrollTop > menuDistance) {
                $menu.addClass('st-sticky').css('top', headerHeight);
                $marker.css('margin-bottom', headerHeight);
              } else {
                $menu.removeClass('st-sticky').css('top', 0);
                $marker.css('margin-bottom', 0);
              }
            }
          };

          stickyMenu();

          $(window).on('scroll resize', function() {
            headerClass();
            stickyMenu();
          });

        }

        scrollToggle();

        var mainMenu = function() {

          var $mainMenuContainer = $('#js-main-menu-container');
          var $mainMenuToggle = $('.js-main-menu-toggle');
          var $mainMenu = $('#js-main-menu');
          var $header = $('#js-header');

          $mainMenu.attr('aria-expanded', 'false');

          var closeMenu = function() {
            $mainMenuContainer.removeClass('is-toggled');
            $header.removeClass('st-menu-open');
            $mainMenuToggle.attr('aria-expanded', 'false').removeClass('is-active');
            $mainMenu.attr('aria-expanded', 'false');
          };

          /* Toggle menu */
          $mainMenuToggle.on('click', function(e) {

            e.preventDefault();
            e.stopImmediatePropagation();

            $mainMenuContainer.fadeToggle(250).css('display', 'flex');

            if ($mainMenuContainer.is('.is-toggled')) {
              closeMenu();
            } else {
              $mainMenuContainer.addClass('is-toggled');
              $header.addClass('st-menu-open');
              $mainMenuToggle.attr('aria-expanded', 'true').addClass('is-active');
              $mainMenu.attr('aria-expanded', 'true');
            }

          });

        };

        mainMenu();

        var catMenu = function() {

          var $menuContainer = $('#js-cat-menu-container');
          var $menuToggle = $('#js-cat-menu-btn');
          var $menu = $('#js-cat-menu');
          var $header = $('#js-header');
          var $menuLink = $('#js-cat-menu a');

          $menu.attr('aria-expanded', 'false');

          var closeMenu = function() {
            $menuContainer.removeClass('is-toggled');
            $header.removeClass('st-cat-menu-open');
            $menuToggle.attr('aria-expanded', 'false').removeClass('is-active');
            $menu.attr('aria-expanded', 'false');
          };

          /* Toggle menu */
          $menuToggle.on('click', function(e) {

            e.preventDefault();
            e.stopImmediatePropagation();

            if ($menuContainer.is('.is-toggled')) {
              $menuContainer.slideUp(250);
              closeMenu();
            } else {
              $menuContainer.slideDown(250);
              $menuContainer.addClass('is-toggled');
              $header.addClass('st-menu-open');
              $menuToggle.attr('aria-expanded', 'true').addClass('is-active');
              $menu.attr('aria-expanded', 'true');
            }

          });

          if (window.matchMedia('(max-width: 1259px)').matches) {
            $menuLink.on('click', function() {
              $menuContainer.slideUp(250);
              closeMenu();
            })
          }

        };

        catMenu();

        var sideMenu = function() {

          $('.js-sidemenu-btn').each(function() {

            var $menuBtn = $(this);
            var $menu = $menuBtn.siblings('.js-sidemenu');

            /* Toggle menu */
            $menuBtn.on('click', function(e) {

              e.preventDefault();
              e.stopImmediatePropagation();

              if ($menu.is('.is-open')) {
                $menuBtn.attr('aria-expanded', 'false').removeClass('is-active');
                $menu.slideUp(250, function() {
                  $menu.removeClass('is-open');
                  $menu.attr('aria-expanded', 'false');
                });
              } else {
                $menuBtn.attr('aria-expanded', 'true').addClass('is-active');
                $menu.slideDown(250, function() {
                  $menu.addClass('is-open');
                  $menu.attr('aria-expanded', 'true');
                });
              }

            });

          });

          if (window.matchMedia("(min-width: 1260px)").matches) {
            $('#js-news-block .js-sidemenu-btn').addClass('is-active');
            $('#js-news-block .js-sidemenu').addClass('is-open');
          }

        };

        sideMenu();

        var anchorLink = function() {

          $('.js-anchor-link').on('click', function(e) {

            e.preventDefault();
            var section = $(this).attr('href');
            var headerHeight = $('#js-header').outerHeight();
            var scrollDistance = $(section).offset().top - headerHeight * 2;

            $('html, body').stop().animate({
              scrollTop: scrollDistance
            }, 800);

          });

        };

        anchorLink();

        new ScrollReveal().reveal('.js-reveal-up', {
          origin: 'bottom',
          delay: 0,
          interval: 100,
          distance: '15px',
          duration: 500,
        });

        new ScrollReveal().reveal('.js-reveal-up-2', {
          origin: 'bottom',
          delay: 0,
          interval: 100,
          distance: '15px',
          duration: 500,
        });

        var parallax = function() {

          var parallaxItem = $('#js-page-header-bg');
          var scrollPos = $(window).scrollTop();

          var animate = function() {
            var itemOffset = scrollPos / 2;
            parallaxItem.css({
              '-webkit-transform': 'translate3d(0, ' + itemOffset + 'px, 0)',
              '-ms-transform': 'translate3d(0, ' + itemOffset + 'px, 0)',
              'transform': 'translate3d(0, ' + itemOffset + 'px, 0)',
              'visibility': 'visible',
              'opacity': 1
            });
          }

          window.requestAnimationFrame(animate);
          
        }

        parallax();

        $(window).on('scroll', parallax);

        $('[data-fancybox]').fancybox({
           buttons: [
            "zoom",
            //"share",
            // "slideShow",
            //"fullScreen",
            //"download",
            "thumbs",
            "close"
          ],
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {

        var catSliders = function() {

          if (window.matchMedia('(min-width: 720px)').matches) {

            $('.js-cat-slider:not(.slick-initialized)').each(function() {

              var $progressBar = $(this).siblings('.js-slider-nav').find('.js-progress-bar');

              $(this).on('init', function(event, slick) {
                var calc = 3 / slick.slideCount;
                $progressBar.css({
                  'transform': 'scaleX(' + calc + ')',
                });
              }).slick({
                slidesToShow: 3,
                rows: 0,
                prevArrow: $(this).siblings('.js-slider-nav').find('.js-slider-btn-prev'),
                nextArrow: $(this).siblings('.js-slider-nav').find('.js-slider-btn-next'),
                infinite: false,
                responsive: [
                  {
                    breakpoint: 720,
                    settings: 'unslick',
                  },
                ],
              });

              $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                var calc = (3 + nextSlide) / slick.slideCount;
                $progressBar.css({
                  'transform': 'scaleX(' + calc + ')',
                });
              });

            });

          }

        };

        catSliders();

        $(window).on('resize', function() {
          catSliders();
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
